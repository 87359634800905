import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import dayjs from "dayjs"

import { useCore } from "../../../../hooks/useCore"
import {
  useCustomerOrders,
  useCustomerOrder,
} from "../../../../hooks/useCustomer"
import { useShopify } from "../../../../hooks/useShopify"
import { useApp } from "../../../../hooks/useApp"
import { useMounted } from "../../../../hooks/useMounted"

export const withOrdersCard = Component => ({ name = "OrdersCard" }) => {
  const {
    helpers: { decodeShopifyId },
  } = useCore()

  const { hasMounted } = useMounted()

  const {
    config: {
      settings: {
        routes: { ORDERS },
      },
    },
    globalState,
  } = useApp()

  const { ordersCard } = useStaticQuery(graphql`
    query SANITY_PAGE_ACCOUNT_ORDERS_CARD {
      ordersCard: sanityPageAccountOrders {
        additionalLoadingText
        additionalNoOrdersText
        additionalOrderNamePrefixText
        additionalOrderPurchasedOnPrefixText
        additionalTrackOrderButtonText
        additionalViewOrderButtonText
      }
    }
  `)

  const {
    additionalLoadingText,
    additionalNoOrdersText,
    additionalOrderNamePrefixText,
    additionalOrderPurchasedOnPrefixText,
    additionalTrackOrderButtonText,
    additionalViewOrderButtonText,
  } = ordersCard || {}

  const { edgeNormaliser, imageNormaliser, formatMoney } = useShopify()

  const { orders, loading, error } = useCustomerOrders(100, { reverse: true })

  const [{ orderDetailActive }, dispatch] = globalState

  const getStatus = (fulfillmentStatus, financialStatus) => {
    if (financialStatus === "REFUNDED") {
      return "Refund"
    } else if (financialStatus === "PARTIALLY_REFUNDED") {
      return "Partially refunded"
    } else if (fulfillmentStatus === "FULFILLED") {
      return "Fulfilled"
    } else if (fulfillmentStatus === "PARTIALLY_FULFILLED") {
      return "Partially fulfilled"
    } else if (fulfillmentStatus !== "RESTOCKED") {
      return "In progress"
    }
  }

  const getSelectedOptionsText = selectedOptions => {
    let text = ""
    selectedOptions?.forEach((option, index) => {
      text = text + `${index > 0 ? ` | ` : ``}${option.name}: ${option.value}`
    })
    return text
  }

  const orderList = orders?.map(({ node }) => ({
    id: node?.id,
    name: node?.name,
    status: getStatus(node?.fulfillmentStatus, node?.financialStatus),
    url: node?.statusUrl,
    date: node?.processedAt
      ? dayjs(node?.processedAt).format("DD/MM/YYYY")
      : "",
    link: `${ORDERS}/${decodeShopifyId(node?.id, "Order")}`,
    lineItems: edgeNormaliser(node?.lineItems)?.map(lineItem => ({
      title: lineItem?.title,
      image: imageNormaliser(lineItem?.variant?.image, 500),
    })),
  }))

  const orderId = hasMounted
    ? location?.pathname.split("/account/orders/")[1] || ""
    : ""

  const key = hasMounted ? location?.search || "" : ""

  const { order, loading: orderLoading, error: orderError } = useCustomerOrder(
    orderId,
    key,
  )

  useEffect(() => {
    if (key && !orderDetailActive) {
      dispatch({
        type: "SHOW_ORDER_DETAIL",
      })
      dispatch({
        type: "SET_ACTIVE_ORDER_NAME",
        payload: order?.name,
      })
    }
  }, [key, orderDetailActive, dispatch, order?.name])

  useEffect(() => {
    if (!key && orderDetailActive) {
      dispatch({
        type: "HIDE_ORDER_DETAIL",
      })
      dispatch({
        type: "SET_ACTIVE_ORDER_NAME",
        payload: "",
      })
    }
  }, [key, orderDetailActive, dispatch])

  const orderItem = order?.name
    ? {
        id: orderId,
        name: order?.name,
        status: getStatus(order?.fulfillmentStatus, order?.financialStatus),
        url: order?.statusUrl,
        date: order?.processedAt
          ? dayjs(order?.processedAt).format("DD/MM/YYYY")
          : "",
        totalPrice: formatMoney(order?.totalPriceV2?.amount),
        subtotalPrice: formatMoney(order?.subtotalPriceV2?.amount),
        totalShippingPrice: formatMoney(order?.totalShippingPrice?.amount),
        currencyCode: order?.currencyCode,
        lineItems: edgeNormaliser(order?.lineItems)?.map(lineItem => ({
          title: lineItem?.title,
          image: imageNormaliser(lineItem?.variant?.image, 500),
          selectedOptions: getSelectedOptionsText(
            lineItem?.variant?.selectedOptions,
          ),
          quantity: lineItem?.currentQuantity,
          originalTotalPrice: formatMoney(lineItem?.originalTotalPrice?.amount),
          discountedTotalPrice: formatMoney(
            lineItem?.discountedTotalPrice?.amount,
          ),
        })),
        shippingAddress: order?.shippingAddress,
      }
    : false

  Component.displayName = name
  return (
    <Component
      order={orderItem}
      orders={orderList}
      loading={loading}
      error={error}
      orderLoading={orderLoading}
      orderError={orderError}
      ordersUrl={ORDERS}
      additionalLoadingText={additionalLoadingText}
      additionalNoOrdersText={additionalNoOrdersText}
      additionalOrderNamePrefixText={additionalOrderNamePrefixText}
      additionalOrderPurchasedOnPrefixText={
        additionalOrderPurchasedOnPrefixText
      }
      additionalTrackOrderButtonText={additionalTrackOrderButtonText}
      additionalViewOrderButtonText={additionalViewOrderButtonText}
    />
  )
}
