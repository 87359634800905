import tw, { styled } from "twin.macro"

import { StyledButton } from "../../../Styled/Button"

export const Wrapper = tw.div`
  relative border-t border-black border-opacity-50 mt-2-4
`

export const Return = styled.button`
  ${tw`w-full absolute -top-4-8 flex flex-row items-center justify-center md:justify-start`}
  span {
    ${tw`font-medium text-14 leading-1.2 tracking-5 uppercase text-red`}
  }
`

export const Header = tw.div`
  flex flex-col md:flex-row justify-between py-2-4 border-b border-black border-opacity-50
`

export const Title = tw.div`flex-1 mb-2-4 md:mb-0`

export const OrderNumber = tw.h3`
  font-bold text-20 md:text-28 leading-1.25 md:leading-1.28 tracking-2 mb-2-4 md:mb-1-2 uppercase
`

export const Date = tw.p`
  text-14 leading-1.78
`

export const StatusWrapper = tw.div`
  flex flex-row md:flex-col justify-between items-center md:items-end
`

export const StatusBadge = tw.div`
  inline-block font-medium text-14 leading-1.2 px-0-6 py-0-4 bg-white flex-grow-0 uppercase md:mb-2-4 tracking-5
`

export const Button = styled(StyledButton)`
  ${tw`block md:mb-1-4 mr-1-6 md:mr-0 p-1 border`}
  :last-of-type {
    ${tw`md:mb-0-8 mr-0`}
  }
`

export const Main = tw.div`flex flex-col items-end`

export const LineItems = tw.ul`w-full`

export const LineItem = styled.li`
  ${tw`py-1-6 md:py-1-8 border-b border-black border-opacity-10 flex flex-row`}
  :last-of-type {
    ${tw`border-b-0`}
  }
`

export const LineItemThumbnail = tw.div`
  w-8-8 h-8-8 mr-2-4 md:mr-5 md:ml-2-1 ml-1-4
`

export const LineItemDetail = tw.div`
  flex-1
`

export const LineItemDetailTitle = tw.div`
  font-bold text-16 md:text-20 leading-1.56 md:leading-1.25 tracking-2 mb-1 md:mb-0-8 uppercase
`

export const LineItemDetailContent = tw.div`
  flex flex-row justify-between
`

export const PriceDetails = tw.ul`
  pt-2-4 md:pt-1-8 pb-1-6 md:pb-1-8 border-t border-black border-opacity-10 md:max-w-30 w-full
`

export const PriceDetail = styled.li`
  ${tw`flex flex-row justify-between items-center mb-0-8`}
  ${({ layout }) => (layout === "total" ? tw`mt-2-2` : null)}
  :last-of-type {
    ${tw`mb-0`}
  }
`

export const PriceDetailTitle = styled.div`
  ${({ layout }) =>
    layout === "total"
      ? tw`text-18 leading-1.56`
      : tw`text-16 leading-1.56 text-grey`}
`

export const PriceDetailAmount = styled.div`
  ${({ layout }) =>
    layout === "total" ? tw`text-20 leading-1.25` : tw`text-18 leading-1.56`}
`

export const ShippingDetail = tw.div`py-2-4 border-t border-black border-opacity-50 flex flex-row`

export const ShippingDetailTitle = tw.div`
  font-bold text-20 leading-1.25 tracking-2 max-w-12-9 mr-3 uppercase
`

export const ShippingDetailContent = tw.div`
  text-14 leading-1.78 flex flex-col
`

export const PriceTag = styled.p<PriceTagProps>`
  ${({ layout }) =>
    layout === "total" ? tw`text-20 leading-1.25` : tw`text-18 leading-1.56`}
  ${({ sale }) => (sale === "true" ? tw`text-red` : null)}
  ${({ strike }) => (strike === "true" ? tw`line-through mr-0-4` : null)}
`

export const Text = tw.p`
  text-16 leading-1.68 text-grey
`

export const Currency = tw.p`
  inline text-16 leading-1.56 text-grey
`

type PriceTagProps = {
  sale?: "true" | "false"
  strike?: "true" | "false"
  layout?: "total"
}
