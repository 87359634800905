import tw, { styled } from "twin.macro"
import { StyledButton } from "../../../Styled/Button"

export const Wrapper = styled.div`
  ${tw`py-2-4 flex flex-col border-t border-black`}
  :last-of-type {
    ${tw`border-b`}
  }
`

export const Header = tw.div`
  flex flex-row mb-1-6 items-end md:items-start
`

export const Title = tw.div`
  flex-1 flex flex-col
`

export const OrderNumber = tw.h3`
  font-bold text-20 leading-1.25 mb-0-6 uppercase
`

export const Date = tw.p`
  text-14 leading-1.78
`

export const StatusBadge = tw.div`
  font-medium text-14 leading-1.2 px-0-6 py-0-4 bg-white flex-grow-0 uppercase tracking-5
`

export const Main = tw.div`
  flex flex-col md:flex-row
`

export const LineItems = tw.ul`
  flex flex-1 flex-col mb-1-8 md:mb-0
`

export const LineItem = styled.li`
  ${tw`py-0-8 flex items-center border-b border-black border-opacity-10 md:max-w-24-5`}
  :last-of-type {
    ${tw`border-b-0`}
  }
`

export const Thumbnail = tw.div`
  w-4-5 h-4-5 mr-2
`

export const LineItemTitle = styled.p`
  ${tw`text-14 leading-1.78`}
`

export const Buttons = tw.div`
  flex flex-row md:flex-col items-end md:max-w-14 md:justify-end
`

export const Button = styled(StyledButton)`
  ${tw`block md:mb-1-4 mr-1-6 md:mr-0 p-1 border`}
  :last-of-type {
    ${tw`md:mb-0-8 mr-0`}
  }
`
