import React from "react"
import { SmallBody } from "../../../Styled/Text"

import { OrdersItem } from "../Item/OrdersItem"

export const OrdersList = ({
  orders,
  loading,
  error,
  additionalNoOrdersText,
  additionalOrderNamePrefixText,
  additionalOrderPurchasedOnPrefixText,
  additionalTrackOrderButtonText,
  additionalViewOrderButtonText,
}): JSX.Element => (
  <div className={`pt-3-2 md:pt-0`}>
    {!loading && orders?.length ? (
      orders?.map(order => (
        <OrdersItem
          key={order?.id}
          {...order}
          additionalOrderNamePrefixText={additionalOrderNamePrefixText}
          additionalOrderPurchasedOnPrefixText={
            additionalOrderPurchasedOnPrefixText
          }
          additionalTrackOrderButtonText={additionalTrackOrderButtonText}
          additionalViewOrderButtonText={additionalViewOrderButtonText}
        />
      ))
    ) : (
      <SmallBody>{additionalNoOrdersText}</SmallBody>
    )}
    {error && <SmallBody>Error loading orders</SmallBody>}
  </div>
)
