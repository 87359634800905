import React from "react"
import { Link } from "gatsby"

import {
  Wrapper,
  Header,
  Title,
  OrderNumber,
  Date,
  StatusBadge,
  Main,
  LineItems,
  LineItem,
  Thumbnail,
  LineItemTitle,
  Buttons,
  Button,
} from "./OrdersItemStyles"
import { Image } from "../../../Image/Image"

export const OrdersItem = ({
  name,
  status,
  url,
  date,
  link,
  lineItems,
  additionalOrderNamePrefixText,
  additionalOrderPurchasedOnPrefixText,
  additionalTrackOrderButtonText,
  additionalViewOrderButtonText,
}): JSX.Element => (
  <Wrapper>
    <Header>
      <Title>
        <OrderNumber>
          {additionalOrderNamePrefixText}&nbsp;{name}
        </OrderNumber>
        <Date>
          {additionalOrderPurchasedOnPrefixText}&nbsp;{date}
        </Date>
      </Title>
      <div>
        <StatusBadge>{status}</StatusBadge>
      </div>
    </Header>
    <Main>
      <LineItems>
        {lineItems?.map((lineItem, index) => (
          <LineItem key={lineItem.title + index}>
            <Thumbnail>
              <Image image={lineItem.image} ratio={"1/1"} />
            </Thumbnail>
            <LineItemTitle>{lineItem.title}</LineItemTitle>
          </LineItem>
        ))}
      </LineItems>
      <Buttons>
        <Button
          as={"a"}
          href={url}
          target={"_blank"}
          wide={"true"}
          colour={"black-reverse"}
          rel="noopener noreferrer"
        >
          {additionalTrackOrderButtonText}
        </Button>
        <Button as={Link} to={link} wide={"true"} colour={"black-reverse"}>
          {additionalViewOrderButtonText}
        </Button>
      </Buttons>
    </Main>
  </Wrapper>
)
