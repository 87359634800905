import React from "react"

import { withOrders } from "./withOrders"
import { OrdersCard } from "./Card/OrdersCard"
import { Layout } from "../Layout/Layout"

export const Orders = withOrders(
  ({ uri, paths }): JSX.Element => (
    <Layout uri={uri} paths={paths}>
      <OrdersCard />
    </Layout>
  )
)
