import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const withOrdersDetail = Component => ({ name = "OrdersDetail", order, ordersUrl }) => {
  const { ordersDetail } = useStaticQuery(graphql`
    query SANITY_PAGE_ACCOUNT_ORDERS_DETAIL {
      ordersDetail: sanityPageAccountOrders {
        additionalBackToOrdersText
        additionalOrderNamePrefixText
        additionalOrderPurchasedOnPrefixText
        additionalQuantityPrefixText
        additionalTrackOrderButtonText
        additionalSubtotal
        additionalShipping
        additionalTotal
        additionalShippingDetails
      }
    }
  `)

  const {
    additionalBackToOrdersText,
    additionalOrderNamePrefixText,
    additionalOrderPurchasedOnPrefixText,
    additionalQuantityPrefixText,
    additionalTrackOrderButtonText,
    additionalSubtotal,
    additionalShipping,
    additionalTotal,
    additionalShippingDetails,
  } = ordersDetail || {}

  Component.displayName = name
  return (
    <Component
      {...order}
      ordersUrl={ordersUrl}
      additionalBackToOrdersText={additionalBackToOrdersText}
      additionalOrderNamePrefixText={additionalOrderNamePrefixText}
      additionalOrderPurchasedOnPrefixText={additionalOrderPurchasedOnPrefixText}
      additionalQuantityPrefixText={additionalQuantityPrefixText}
      additionalTrackOrderButtonText={additionalTrackOrderButtonText}
      additionalSubtotal={additionalSubtotal}
      additionalShipping={additionalShipping}
      additionalTotal={additionalTotal}
      additionalShippingDetails={additionalShippingDetails}
    />
  )
}
